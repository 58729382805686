import React, {useState, useEffect, useContext} from "react";
import {DataAccess} from "../data-access/data-access";
import {Outlet, useNavigate} from "react-router-dom";
import {LoadingScreen} from "./LoadingScreen";
import {UserContext} from "../../context";
import {useIdleTimer} from "react-idle-timer";
import {toast} from "react-toastify";

export const PrivateRouter = () => {
    const navigate = useNavigate();
    const {user, setUser} = useContext(UserContext);
    const [isActive, setIsActive] = useState<boolean>(true);

    const onIdle = () => {
        setIsActive(false);
    }

    const onActive = () => {
        // poll profile, if error, then session is expired so
        // redirect to home and set local state null
        if (!isActive) {
            DataAccess.get("/api/user/profile.json")
                .then(_ => {
                    setIsActive(true);
                })
                .catch(_ => {
                    setUser(null);
                    navigate("/");
                    toast.error("Session expired, you've been logged out.");
                })
        } else {
            setIsActive(true);
        }
    }

    useIdleTimer({
        onIdle: onIdle,
        onActive: onActive,
        timeout: 300000 // 5 mins
    });

    useEffect(() => {
        if (!user) {
            DataAccess.get("/api/user/profile.json")
                .then(r => {
                    // check if email confirmed, if no, then redirect to confirm email page
                    if (!r.isEmailConfirmed) {
                        navigate(`/confirmEmail/reminder/${r.email}`);
                    } else {
                        // if email confirmed, assign user profile via handleAuthentication
                        setUser(r);
                    }
                })
                .catch(_ => {
                    navigate("/login");
                })
        }
    }, [user, setUser, navigate])

    return (user) ? <Outlet /> : <LoadingScreen />
}