import React, {useCallback, useContext, useState} from "react";
import {
    useTheme,
    Avatar,
    Chip,
    Container,
    Grid,
    Typography,
    Button,
    Divider,
    Tabs, Tab,
    IconButton,
    Tooltip
} from "@mui/material";
import {
    DateProgressBar,
    LoadingSpinner,
    RoundTimeline,
    ErrorComponent,
    ConfirmActionModal,
    EmailEntry,
    ShareAccessModal
} from "../../components";
import {isAfter, parseISO, addMinutes} from "date-fns";
import {Link, useParams, useNavigate} from "react-router-dom";
import competition_placeholder from "../../img/competition_placeholder.jpg";
import {useCompetitionDetail, useRoundDelete, useCompetitionDelete, useShareAccess} from "../../hooks";
import {toast} from "react-toastify";
import {DataAccess, formatError} from "../../util";
import {InvitationStatus} from "./InvitationStatus";
import {ConfigurationTable} from "./ConfigurationTable";
import ErrorIcon from "@mui/icons-material/Error";
import {UserContext} from "../../context";
import PeopleIcon from '@mui/icons-material/People';

type InvitationTab = "status" | "inviteUsers";

export function AdminCompetitionDetail() {
    const {user} = useContext(UserContext);
    const theme = useTheme();
    const {competitionId} = useParams();
    const navigate = useNavigate();
    const {requestStatus, competitionDetail} = useCompetitionDetail(competitionId);
    const {sharedUsers, getSharedUsers} = useShareAccess("EVENT", competitionId);
    const {submitDelete} = useRoundDelete();
    const {deleteCompetition} = useCompetitionDelete();
    const [selectedRoundId, setSelectedRoundId] = useState<number>(NaN);
    const [deleteRoundModalOpen, setDeleteRoundModalOpen] = useState<boolean>(false);
    const [deleteCompetitionModalOpen, setDeleteCompetitionModalOpen] = useState<boolean>(false);
    const [shareAccessModalOpen, setShareAccessModalOpen] = useState<boolean>(false);

    const [invitationTab, setInvitationTab] = useState<InvitationTab>("status");
    const [invitationEmails, setInvitationEmails] = useState<string[]>([]);

    const handleDeleteClick = (id: number) => {
        setSelectedRoundId(id);
        setDeleteRoundModalOpen(true);
    }

    const handleTabChange = (_: React.SyntheticEvent, newValue: string) => {
        setInvitationTab(newValue as InvitationTab);
    }

    const handleDeleteCompetition = () => {
        if (competitionId) {
            deleteCompetition(parseInt(competitionId))
                .then(_ => {
                    toast.success("Competition deleted successfully.");
                    navigate("/admin/events");
                })
                .catch(e => {
                    console.log(e);
                    toast.error(formatError(e));
                })
        }
    }

    const handleOpenShareAccessModal = () => {
        getSharedUsers()
            .then(_ => {
                setShareAccessModalOpen(true);
            })
            .catch(e => {
                console.log(e);
                toast.error("There was a problem with this request");
            })
    }

    const handleCloseShareAccessModal = () => {
        setShareAccessModalOpen(false);
    }

    const deleteRoundAction = () => {
        submitDelete(selectedRoundId)
            .then(_ => {
                setDeleteRoundModalOpen(false);
                navigate(0);
            })
            .catch(e => {
                console.log(e);
                toast.error(formatError(e));
            })
    }

    const addInvitationEmails = useCallback((emails: string[]) => {
        setInvitationEmails(prev => [...prev, ...emails]);
    }, [])

    const removeInvitationEmail = useCallback((email: string) => {
        setInvitationEmails(prev => prev.filter(e => e !== email));
    }, []);

    const submitInvitations = useCallback(() => {
        const invitationData = {
            emails: invitationEmails
        }
        DataAccess.post(`/api/invitation/${competitionId}/create.json`, {data: invitationData})
            .then(_ => {
                navigate(0);
            })
            .catch(e => {
                toast.error("There was a problem in creating these invitations.");
                console.log(e);
            });
    }, [competitionId, invitationEmails, navigate]);

    const canModify = !!user && user.role === "admin";

    if (requestStatus === "loading" || competitionDetail === null) {
        return <LoadingSpinner />
    } else if (requestStatus === "error") {
        return <ErrorComponent />
    } else {

        const now = new Date();
        const deleteDisabled: boolean = (competitionDetail.roundMeta.length !== 0 && isAfter(now, parseISO(competitionDetail.roundMeta[0].responseStartDate + "Z"))) ||
            (competitionDetail.invitationDetail.candidates.length > 0 || competitionDetail.invitationDetail.users.length > 0) ||
            (competitionDetail.invitationDetail.openEnrolledUsers.length > 0);

        // can invite more users if no rounds scheduled OR first round end date hasn't elapsed
        const inviteDisabled = (competitionDetail.roundMeta.length !== 0 &&
            isAfter(addMinutes(new Date(), 24 * 60), parseISO(competitionDetail.roundMeta[0].responseEndDate + "Z"))) ||
            isAfter(new Date(), parseISO(competitionDetail.competitionMeta.endDate + "Z"));

        const shareAccessDisabled = !!user && (user.userId !== competitionDetail.competitionMeta.createdBy);

        return (
            <Container sx={{mb: 10}}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={2} justifyContent="center" alignItems="center">
                            <Grid item xs={1}>
                                <Avatar
                                    src={competition_placeholder}
                                    alt="event-avatar"
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <Typography variant="h4" sx={{ml: 3}}>
                                    {competitionDetail.competitionMeta.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sx={{textAlign: "right"}}>
                                {competitionDetail.competitionMeta.tags.map((c, i) => (
                                        <Chip
                                            sx={{mr: 2, bgcolor: theme.palette.primary.light}}
                                            variant="filled"
                                            label={c.name}
                                            key={i}
                                            size="small"
                                        />
                                    )
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <DateProgressBar
                                    start={competitionDetail.competitionMeta.visibilityDate}
                                    end={competitionDetail.competitionMeta.endDate}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Divider sx={{mt: 3, mb: 5}} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={2}>
                        <Grid item xs={6} sx={{textAlign: "center"}}>
                            <Typography variant="h5">
                                Round Timeline
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{textAlign: "center"}}>
                            <Button
                                component={Link}
                                variant="contained"
                                color="primary"
                                to={`/admin/events/results/${competitionId}`}
                                sx={{ml: 3}}
                            >
                                See Results
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <RoundTimeline
                                roundMeta={competitionDetail.roundMeta}
                                competitionMeta={competitionDetail.competitionMeta}
                                handleDeleteClick={handleDeleteClick}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider sx={{mt: 3, mb: 3}} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container spacing={2}>
                        <Grid container item xs={12} spacing={2} justifyContent="space-between">
                            <Grid item>
                                <Typography variant="h5">
                                    Configuration
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Tooltip title="See who has been shared this event." placement="left">
                                    <IconButton
                                        onClick={handleOpenShareAccessModal}
                                        disabled={shareAccessDisabled}
                                    >
                                        <PeopleIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <ConfigurationTable meta={competitionDetail.competitionMeta} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider sx={{mt: 3, mb: 3}} />
                    </Grid>
                    <Grid container item xs={12} spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h5">
                                Invitation Status
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Tabs
                                value={invitationTab}
                                sx={{my: 3}}
                                onChange={handleTabChange}
                            >
                                <Tab value="status" label="Invitation Status" />
                                <Tab value="inviteUsers" label="Invite Users" disabled={inviteDisabled || !canModify} />
                            </Tabs>
                        </Grid>
                        {invitationTab === "status" ?
                            <InvitationStatus invitationDetail={competitionDetail.invitationDetail} /> :
                            <>
                                <EmailEntry
                                    emails={invitationEmails}
                                    addEmails={addInvitationEmails}
                                    removeEmail={removeInvitationEmail}
                                />
                                <Grid item xs={12}>
                                    <Button
                                        color="primary"
                                        onClick={submitInvitations}
                                        variant="contained"
                                        disabled={invitationEmails.length === 0}
                                        sx={{mt: 2}}
                                    >
                                        Send Invitations
                                    </Button>
                                </Grid>
                            </>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Divider sx={{mt: 3, mb: 3}} />
                    </Grid>
                    <Grid item xs={12} container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h5">
                                Delete Event
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Typography variant="subtitle2">
                                Deleting event will remove all data related to the event.  You will retain any video prompts, scripts, or file assets that you have previously uploaded.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} sx={{textAlign: "center"}}>
                            <Button
                                color="secondary"
                                variant="contained"
                                startIcon={<ErrorIcon />}
                                disabled={deleteDisabled || !canModify}
                                onClick={() => setDeleteCompetitionModalOpen(true)}
                            >
                                Delete Event
                            </Button>
                        </Grid>

                    </Grid>
                </Grid>
                <ConfirmActionModal
                    open={deleteRoundModalOpen}
                    setOpen={setDeleteRoundModalOpen}
                    action={deleteRoundAction}
                >
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="h6" sx={{mb: 2}}>
                                Delete Round
                            </Typography>
                            <Typography variant="subtitle2">
                                Are you sure you want to delete this round?  This action cannot be undone.
                            </Typography>
                        </Grid>
                    </Grid>
                </ConfirmActionModal>
                <ConfirmActionModal
                    open={deleteCompetitionModalOpen}
                    setOpen={setDeleteCompetitionModalOpen}
                    action={handleDeleteCompetition}
                    confirmActionText="Delete"
                >
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                Delete Event
                            </Typography>
                            <Typography variant="subtitle2">
                                Are you sure you want to delete this Event?  This cannot be undone.  Users who have enrolled will be notified via email and/or notification.
                            </Typography>
                        </Grid>
                    </Grid>
                </ConfirmActionModal>
                <ShareAccessModal
                    open={shareAccessModalOpen}
                    onClose={handleCloseShareAccessModal}
                    sharedUsers={sharedUsers}
                />
            </Container>
        )
    }
}
