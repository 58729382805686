import React from "react";
import {Modal, Grid, Typography, List, ListItem, ListItemText, Divider, IconButton} from "@mui/material";
import {ModalBox} from "../modal-box";
import {SharedUser} from "../../data-types";
import {DataFormatting} from "../../util";
import CancelIcon from '@mui/icons-material/Cancel';

interface ShareAccessModalProps {
    open: boolean;
    sharedUsers: SharedUser[];
    onClose: () => void;
}

export function ShareAccessModal({open, sharedUsers, onClose}: ShareAccessModalProps) {

    return (
        <Modal open={open} onClose={onClose}>
            <ModalBox>
                <Grid container>
                    <Grid container spacing={2} item xs={12} justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Typography variant="h6">
                                Share Access
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton
                                onClick={onClose}
                            >
                                <CancelIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider sx={{my: 2}} />
                    </Grid>
                    <Grid item xs={12}>
                        {sharedUsers.length === 0 ?
                            <Typography variant="h6">
                                You have not shared this with anyone.
                            </Typography> :
                            <List dense>
                                {sharedUsers.map((c, i) =>
                                    <ListItem
                                        key={i}
                                        secondaryAction={
                                            <Typography variant="subtitle2">
                                                {DataFormatting.roleIdToString(c.roleId)}
                                            </Typography>
                                        }
                                    >
                                        <ListItemText
                                            primary={`${c.firstName} ${c.lastName}`}
                                            secondary={
                                                <>
                                                    {c.username} <br /> {c.email}
                                                </>
                                            }
                                        />
                                    </ListItem>
                                )}
                            </List>
                        }
                    </Grid>
                </Grid>
            </ModalBox>
        </Modal>
    )
}