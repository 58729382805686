import React from "react";
import {Divider, Container, Grid, Avatar, Typography, Box, Button, Paper} from "@mui/material";
import {LoadingSpinner, ErrorComponent} from "../../components";
import {useParams} from "react-router-dom";
import {useAdminUserDetail} from "../../hooks";
import {AgeOptions, CountryOptions, LanguageOptions} from "../../util";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import StarIcon from '@mui/icons-material/Star';
import {parseISO} from "date-fns";

export function AdminUserDetail() {

    const {userId} = useParams();
    const {
        requestStatus,
        userDetail
    } = useAdminUserDetail(userId);

    const ageFormat = (age: number | undefined): string => {
        const a = AgeOptions.find(c => c.value === age);
        return a ? a.label : "Not Specified";
    }

    const countryFormat = (country: string | undefined): string => {
        const a = CountryOptions.find(c => c.value === country);
        return a ? a.label : "Not Specified";
    }

    const languageFormat = (language: string | undefined): string => {
        const a = LanguageOptions.find(c => c.value === language);
        return a ? a.label : "Not Specified";
    }

    const residentFormat = (usResident: boolean | undefined): string => {
        if (usResident === false) {
            return "No";
        } else if (usResident === true) {
            return "Yes";
        } else {
            return "Not specified";
        }
    }

    const roleMap = (role: string) => {
        let result: string;
        switch (role) {
            case "admin":
                result = "Admin";
                break;
            case "view":
                result = "Viewer";
                break;
            case "p_admin":
                result = "Prompt Admin";
                break;
            case "ps_admin":
                result = "Prompt Script Admin";
                break;
            case "user":
                result = "User";
                break;
            default:
                result = "";
                break;
        }
        return result;
    }

    if (requestStatus === "loading") {
        return <LoadingSpinner />;
    } else if (requestStatus === "error") {
        return <ErrorComponent />;
    } else {
        return (
            <Container sx={{mb: 10}}>
                <Grid container spacing={2} alignItems="center" sx={{mb: 7}}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Box component="div"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Avatar
                                src={userDetail.avatar ? `data:image/jpeg;base64,${userDetail.avatar}` : ""}
                                imgProps={{alt: `${userDetail.username}'s Avatar`}}
                                sx={{height: 150, width: 150}}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} sx={{textAlign: {xs: "center", sm: "center", md: "right"}}}>
                        <Typography variant="h4">
                            {userDetail.username}
                        </Typography>
                        {userDetail.role !== "user" ?
                            <Button
                                variant="outlined"
                                color="warning"
                                startIcon={<StarIcon />}
                                sx={{pointerEvents: "none", mt: 2}}
                                size="small"
                            >
                                {roleMap(userDetail.role)}
                            </Button> :
                            null
                        }
                        <Typography variant="h6" sx={{mt: 3}}>
                            {`${userDetail.firstName} ${userDetail.lastName}`}
                        </Typography>
                        <Typography variant="body1">
                            {userDetail.isEmailConfirmed ?
                                <CheckCircleIcon color="primary" sx={{verticalAlign: "middle", mr: 1}} /> :
                                <ErrorIcon color="secondary" sx={{verticalAlign: "middle", mr: 1}} />
                            }
                            {userDetail.email}
                        </Typography>
                        <Typography variant="body1">
                            {`User since:  ${parseISO(userDetail.createDate + "Z").toLocaleDateString()}`}
                        </Typography>
                        <Typography variant="body1">
                            {`Applyweb ID:  ${userDetail.applywebAccountId ?? "None"}`}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Demographics
                        </Typography>
                        <Divider sx={{my: 3}}/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Paper elevation={1} sx={{p: 2}}>
                            <Typography variant="subtitle2">
                                {`Age:  ${ageFormat(userDetail.ageBracket)}`}
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Paper elevation={1} sx={{p: 2}}>
                            <Typography variant="subtitle2">
                                {`Country:  ${countryFormat(userDetail.regionOrCountry)}`}
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Paper elevation={1} sx={{p: 2}}>
                            <Typography variant="subtitle2">
                                {`Primary Language:  ${languageFormat(userDetail.primaryLanguage)}`}
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Paper elevation={1} sx={{p: 2}}>
                            <Typography variant="subtitle2">
                                {`Secondary Language:  ${languageFormat(userDetail.secondaryLanguage)}`}
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Paper elevation={1} sx={{p: 2}}>
                            <Typography variant="subtitle2">
                                {`Mailing Postal Code:  ${userDetail.mailingZip ?? "Not Specified"}`}
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Paper elevation={1} sx={{p: 2}}>
                            <Typography variant="subtitle2">
                                {`High School Postal Code:  ${userDetail.highSchoolZip ?? "Not Specified"}`}
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Paper elevation={1} sx={{p: 2}}>
                            <Typography variant="subtitle2">
                                {`US Resident:  ${residentFormat(userDetail.usResident)}`}
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        )
    }
}