import React, {ChangeEvent, useState} from "react";
import {Button, Container, Divider, Grid, Paper, Typography, TextField, MenuItem} from "@mui/material";
import {useChangeRoles} from "../../hooks";
import {toast} from "react-toastify";
import {formatError} from "../../util";

export function AdminUsers() {

    const [toInvite, setToInvite] = useState<string>("");
    const [adminLevel, setAdminLevel] = useState<number>(2);
    const {
        inviteAdmin
    } = useChangeRoles();

    const handleSubmit = () => {
        inviteAdmin(toInvite, adminLevel)
            .then(_ => {
                toast.success(`${toInvite} has had their permissions elevated.`);
                setToInvite("");
            })
            .catch(e => {
                toast.error(formatError(e));
            })
    }

    const handleRoleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;
        setAdminLevel(Number.parseInt(value));
    };

    return (
        <Container sx={{mb: 10}}>
            <Grid container sx={{mt: 2, mb: 5}}>
                <Grid item xs={12}>
                    <Typography variant="h4">
                        Admin User Configuration
                    </Typography>
                    <Divider sx={{my: 3}} />
                </Grid>
                <Paper component={Grid} elevation={3} container item xs={12} sm={12} md={6} sx={{p: 2}} alignItems="center">
                    <Grid item xs={12} sx={{mb: 2}}>
                        <Typography variant="h6">
                            Make User Admin
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{mb: 2}}>
                        <Typography variant="subtitle2">
                            Enter a user's email address to promote them to an admin if their account exists.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={9} sx={{p: 2}}>
                        <TextField
                            label="Email address"
                            fullWidth
                            value={toInvite}
                            onChange={(e) => setToInvite(e.target.value)}
                            onKeyDown={(e) => e.key === 'Enter' ? handleSubmit() : null}
                        />
                        <TextField
                            label="Admin Level"
                            fullWidth
                            select
                            value={adminLevel}
                            onChange={handleRoleChange}
                            sx={{mt: 3}}
                        >
                            <MenuItem value={2}>
                                Viewer
                            </MenuItem>
                            <MenuItem value={5}>
                                Prompt Admin
                            </MenuItem>
                            <MenuItem value={4}>
                                Prompt + Script Admin
                            </MenuItem>
                            <MenuItem value={3}>
                                Admin
                            </MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={3} sx={{p: 2}}>
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{width: "100%"}}
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Paper>
            </Grid>
        </Container>
    )
}