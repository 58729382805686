import React, {useState} from "react";
import {
    Container,
    Chip,
    Grid,
    TextField,
    useTheme,
    Typography,
    InputAdornment,
    IconButton, Box,
    MenuItem, Paper,
    Checkbox, FormControlLabel,
    FormGroup,
    Tabs,
    Tab
} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import AddIcon from '@mui/icons-material/Add';
import {DataAccess, formatError} from "../../util";
import {useNavigate, useParams} from "react-router-dom";
import {EmailEntry, LoadingSpinner, ErrorComponent, FileDropUpload} from "../../components";
import {useCompetitionCreate, useLegalAgreements} from "../../hooks";
import {FileUploadConfig} from "../../data-types";
import {isAfter} from "date-fns";
import {toast} from "react-toastify";

type AccessSelect = {value: string; label: string};
type LegalAgreementTab = "uploadNew" | "usePreviouslyUploaded";

export function AdminCreateCompetition() {
    const navigate = useNavigate();
    const {competitionId, action} = useParams();
    const theme = useTheme();
    const {
        competitionState,
        cachedState,
        handleTextChange,
        handleCurrencyChange,
        handleDateChange,
        handleAddTag,
        handleCheck,
        handleRemoveTag,
        handleAddWhitelist,
        handleRemoveWhitelist,
        handleAddBlacklistEmails,
        handleRemoveBlacklistEmail,
        handleAddInvitationEmails,
        handleRemoveInvitationEmail,
        submitCompetition,
        requestStatus
    } = useCompetitionCreate(competitionId, action);
    const {legalAgreements} = useLegalAgreements();
    const [loading, setLoading] = useState<boolean>(false);
    const [tagState, setTagState] = useState<string>("");
    const [whitelistState, setWhitelistState] = useState<string>("");
    const [legalAgreementUploadConfig, setLegalAgreementUploadConfig] = useState<FileUploadConfig | null>(null);
    const [legalAgreementTab, setLegalAgreementTab] = useState<LegalAgreementTab>("usePreviouslyUploaded");

    const handleLegalTabChange = (_: React.SyntheticEvent, newValue: string) => {
        const newTab = newValue as LegalAgreementTab;
        setLegalAgreementTab(newTab);
    }

    const handleTagChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;
        setTagState(value);
    }

    const handleWhitelistChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;
        setWhitelistState(value);
    }

    const accessSelectOptions: AccessSelect[] = [
        {value: "public", label: "Public"},
        {value: "email-whitelist", label: "Email Whitelist"},
        {value: "invite-only", label:  "Invite Only"}
    ]

    const submit = async () => {
        try {
            setLoading(true);

            let config; // file upload config
            let state = competitionState;

            // if usePreviouslyUploaded, don't use any uploaded files (i.e. set config to null)
            if (legalAgreementTab === "usePreviouslyUploaded") {
                const fileName = legalAgreements.find(c => c.legalAgreementUuid === competitionState.legalAgreementUuid)?.legalAgreementFilename;
                state = {...state, legalAgreementName: fileName};
                config = null
            } else {
                // if using uploaded file, then remove legalAgreementName and legalAgreementUuid from state.  set config
                const {legalAgreementUuid, legalAgreementName, ...rest} = state;
                state = rest;
                config = legalAgreementUploadConfig;
            }
            const createdCompetition = await submitCompetition(state, config);

            // only send invites if doing creation or copy, not edit
            if (action === "copy" || action === "create") {
                const invitationData = {
                    emails: competitionState.invitationEmails
                }
                // post invitations
                await DataAccess.post(`/api/invitation/${createdCompetition.competitionId}/create.json`, {data: invitationData});
            }
            navigate(`/admin/events`);
        } catch(e) {
            console.log(e);
            toast.error(formatError(e));
        } finally {
            setLoading(false);
        }
    }

    const accessLevelHelpText: JSX.Element = (competitionState.accessibility === "public") ?
        <>
            <Typography variant="body1">
                The Public access level means that this event is open to anybody who has created an account.  Users are permitted to join up until the start of the event.
            </Typography>
            <br />
            <Typography variant="body1">
                Users will be able to search for and enroll in this event of their own volition.
            </Typography>
        </> :
            (competitionState.accessibility === "email-whitelist") ?
                <>
                    <Typography variant="body1">
                        The Email Whitelist access level means that this event is open to anybody whose email address domain matches one of the specified whitelist domains.
                    </Typography>
                    <br />
                    <Typography variant="body1">
                        Users with a matching email are permitted to join up until the start of the event.
                    </Typography>
                </> :
                <>
                    <Typography variant="body1">
                        The Invite Only access level means that ONLY those users invited in step 4 will be able to join the event.
                    </Typography>
                    <br />
                    <Typography variant="body1">
                        Users that aren't invited will not be able to see this event when they search for those to join.
                    </Typography>
                </>;

    if (requestStatus === "loading") {
        return <LoadingSpinner />;
    } else if (requestStatus === "error") {
        return <ErrorComponent />
    } else {
        return (
            <Container sx={{
                width: "100%",
                p:5
            }}>
                <Box sx={{mb: 12}} component="div">
                    <Typography
                        align="center"
                        variant="h4"
                        sx={{mb: 2}}
                    >
                        {(competitionState.competitionName === "") ? "Create Event" : competitionState.competitionName}
                    </Typography>
                </Box>
                <Typography
                    variant="h5"
                    sx={{mb: 2}}
                >
                    1.)  Add a name, description and tags
                </Typography>
                <Grid container spacing={2} sx={{mb: 12}}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            label="Event Name"
                            fullWidth={true}
                            name="competitionName"
                            value={competitionState.competitionName}
                            inputProps={{size: 40}}
                            onChange={handleTextChange}
                            sx={{mb: 3}}
                        />
                        <TextField
                            label="Description"
                            name="competitionDescription"
                            fullWidth={true}
                            multiline
                            rows={4}
                            value={competitionState.competitionDescription}
                            inputProps={{size: 40}}
                            onChange={handleTextChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Enter a tag to add"
                            name="tags"
                            fullWidth={true}
                            inputProps={{size: 40}}
                            onChange={handleTagChange}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    handleAddTag(tagState);
                                    setTagState("");
                                }
                            }}
                            value={tagState}
                            sx={{mb: 3}}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            edge="end"
                                            color="primary"
                                            disabled={tagState === ""}
                                            onClick={() => {
                                                handleAddTag(tagState);
                                                setTagState("");
                                            }}
                                        >
                                            <AddIcon />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        <Typography
                            variant="h6"
                            sx={{mb: 2}}
                        >
                            Tags
                        </Typography>
                        {competitionState.tags.map((c, i) => (
                            <Chip
                                sx={{mr: 2, bgcolor: theme.palette.primary.light}}
                                variant="filled"
                                label={c}
                                key={i}
                                size="small"
                                onDelete={() => handleRemoveTag(c)}
                            />
                        ))}
                    </Grid>
                </Grid>
                <Typography
                    variant="h5"
                    sx={{mb: 2}}
                >
                    2.)  Set a visibility and end date
                </Typography>
                <Grid container spacing={2} sx={{mb: 12}} alignItems="center" justifyContent="center">
                    <Grid item xs={12}>
                        <Paper elevation={1} sx={{p: 3, mb: 3}}>
                            <Typography variant="subtitle2" sx={{mb: 2}}>
                                Visibility date refers to the earliest date that a user can join an event.  Once the visibility date elapses, the event will become 'visible' on the users dashboard if users meet the access requirements in section 3.
                            </Typography>
                            <Typography variant="subtitle2">
                                In general, users can join an event up until the end of the first round's response phase. Additionally, any users that are invited to the event will have invitation emails sent once the Visibility date elapses.
                            </Typography>
                        </Paper>
                    </Grid>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Grid item xs={12} sm={6} sx={{textAlign: 'center'}}>
                            <DateTimePicker
                                value={competitionState.visibilityDate}
                                onChange={(value) => handleDateChange("visibilityDate", value)}
                                label={<Box component="div" sx={{color: "rgba(255, 255, 255, 0.7)"}}>Visibility Date</Box>}
                                disabled={action === "edit" && isAfter(new Date(), cachedState!.visibilityDate)}
                                disablePast={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{textAlign: 'center'}}>
                            <DateTimePicker
                                label="End Date"
                                onChange={(value) => handleDateChange("endDate", value)}
                                value={competitionState.endDate}
                                disablePast={true}
                            />
                        </Grid>
                    </LocalizationProvider>
                </Grid>
                <Typography
                    variant="h5"
                    sx={{mb: 2}}
                >
                    3.)  Access Configuration
                </Typography>
                <Grid container spacing={2} sx={{mb: 6}}>
                    <Grid container item xs={12} alignItems="center" justifyContent="space-between" sx={{p: 3}}>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                a.) Access Level
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} sx={{p: 3}}>
                            <Box component="div" sx={{display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"}}>
                                <TextField
                                    label="Access Level"
                                    name="accessibility"
                                    id="accessibility"
                                    select
                                    value={competitionState.accessibility}
                                    onChange={handleTextChange}
                                    sx={{width: 250}}
                                    disabled={action === "edit"}
                                >
                                    {accessSelectOptions.map((c, i) => (
                                        <MenuItem key={i} value={c.value}>
                                            {c.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                {competitionState.accessibility === "email-whitelist" ?
                                    <TextField
                                        label="Add a whitelisted domain"
                                        name="whitelist"
                                        fullWidth={true}
                                        inputProps={{size: 40}}
                                        onChange={handleWhitelistChange}
                                        value={whitelistState}
                                        sx={{mb: 3, mt: 3, width: 250}}
                                        disabled={action === "edit"}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        edge="end"
                                                        color="primary"
                                                        disabled={whitelistState === ""}
                                                        onClick={() => {
                                                            handleAddWhitelist(whitelistState);
                                                            setWhitelistState("");
                                                        }}
                                                    >
                                                        <AddIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    /> :
                                    null
                                }
                            </Box>
                            {competitionState.accessibility === "email-whitelist" ?
                                <Box component="div">
                                    <Typography
                                        variant="body1"
                                        sx={{mb: 2}}
                                    >
                                        {`Whitelist (${competitionState.whitelistedSuffixes.length}): `}
                                    </Typography>
                                    {competitionState.whitelistedSuffixes.map((c, i) => (
                                        <Chip
                                            sx={{mr: 2, bgcolor: theme.palette.primary.light}}
                                            variant="filled"
                                            label={c}
                                            key={i}
                                            size="small"
                                            disabled={action === "edit"}
                                            onDelete={() => handleRemoveWhitelist(c)}
                                        />
                                    ))}
                                </Box> :
                                null
                            }
                        </Grid>
                        <Paper component={Grid} elevation={1} item xs={12} sm={12} md={6} sx={{p: 3, mb: 3}}>
                            {accessLevelHelpText}
                        </Paper>
                        <Grid item xs={12} sx={{mb: 3}}>
                            <Typography variant="h6">
                                b.)  Profile Requirements
                            </Typography>
                            <Paper component={Box} elevation={1} sx={{p: 3, mt: 3}}>
                                <FormGroup sx={{pl: {md: 10, sm: 0}}}>
                                    <FormControlLabel
                                        sx={{mb: 2}}
                                        control={
                                            <Checkbox
                                                checked={competitionState.requiresProfile}
                                                onChange={handleCheck}
                                                disabled={action === "edit"}
                                            />
                                        }
                                        label="Check this box to require users complete their profile to enroll"
                                        name="requiresProfile"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={competitionState.requiresUsResident}
                                                onChange={handleCheck}
                                                disabled={action === "edit"}
                                            />
                                        }
                                        label="Check this box to require that users be U.S. residents or citizens to enroll"
                                        name="requiresUsResident"
                                    />
                                </FormGroup>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sx={{mb: 3}}>
                            <Typography variant="h6" sx={{mb: 3}}>
                                c.)  Legal Agreement
                            </Typography>
                            {(action === "edit") ?
                                <Typography variant="subtitle2">
                                    {`Uploaded file:  ${competitionState.legalAgreementName || "None"}`}
                                </Typography> :
                                <Grid container>
                                    <Grid item xs={12} sx={{mb: 4}}>
                                        <Tabs value={legalAgreementTab} onChange={handleLegalTabChange}>
                                            <Tab label="Use Previously Uploaded" value="usePreviouslyUploaded" />
                                            <Tab label="Upload New" value="uploadNew" />
                                        </Tabs>
                                    </Grid>
                                    {legalAgreementTab === "usePreviouslyUploaded" ?
                                        <Grid item xs={12}>
                                            <TextField
                                                select
                                                id="legalAgreementSelect"
                                                label="Legal Agreement"
                                                name="legalAgreementUuid"
                                                value={competitionState.legalAgreementUuid || ""}
                                                onChange={handleTextChange}
                                                sx={{width: {md: "50%", xs: "100%"}, mb: 2}}
                                            >
                                                {legalAgreements.map((c, i) =>
                                                    <MenuItem
                                                        key={i}
                                                        value={c.legalAgreementUuid}
                                                    >
                                                        {c.legalAgreementFilename}
                                                    </MenuItem>
                                                )}
                                            </TextField>
                                        </Grid> :
                                        <Grid item xs={12}>
                                            <FileDropUpload
                                                maxFileSize={5000000}
                                                acceptedFileTypes={{"APPLICATION/PDF": [".pdf"]}}
                                                uploadConfig={legalAgreementUploadConfig}
                                                setUploadConfig={setLegalAgreementUploadConfig}
                                            />
                                        </Grid>
                                    }
                                </Grid>
                            }
                        </Grid>
                        <Grid item xs={12} sx={{mb: 3}}>
                            <Typography variant="h6">
                                d.)  Cost Per Participant
                            </Typography>
                            <Paper component={Box} elevation={1} sx={{p: 3, mt: 3}}>
                                <TextField
                                    required
                                    label="Cost"
                                    onWheel={(e) => (e.target as HTMLElement).blur()}
                                    fullWidth={true}
                                    name="paymentParticipant"
                                    error={competitionState.paymentParticipant < 0}
                                    helperText={(competitionState.paymentParticipant < 0) ? "Cost cannot be negative" : ""}
                                    value={(competitionState.paymentParticipant / 100).toFixed(2)}
                                    disabled={action === "edit"}
                                    inputProps={{size: 40, style: {caretColor: "transparent"}}}
                                    InputProps={{startAdornment:<InputAdornment position="start">$</InputAdornment>}}
                                    onKeyDown={(e) => handleCurrencyChange(e)}
                                    sx={{mb: 3}}
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sx={{mb: 3}}>
                            <Typography variant="h6">
                                e.)  Prize Description
                            </Typography>
                            <Paper component={Box} elevation={1} sx={{p: 3, mt: 3}}>
                                <TextField
                                    label="Prize Description"
                                    fullWidth={true}
                                    name="prizeDescription"
                                    onChange={handleTextChange}
                                    error={(competitionState.prizeDescription.match(/\n/g)?.length || 0) > 2}
                                    helperText="* Please limit to 3 lines."
                                    value={competitionState.prizeDescription}
                                    inputProps={{size: 40}}
                                    multiline
                                    rows={3}
                                    maxRows={3}
                                    minRows={3}
                                    sx={{mb: 3}}
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sx={{mb: 3}}>
                            <Typography variant="h6">
                                f.)  Institution Association
                            </Typography>
                            <Paper component={Box} elevation={1} sx={{p: 3, mt: 3}}>
                                <FormGroup sx={{pl: {md: 10, sm: 0}}}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={competitionState.isSingleInstSelection}
                                                onChange={handleCheck}
                                                disabled={action === "edit"}
                                            />
                                        }
                                        label="Check this box to lock users into selecting one institutional assignment for optional prompts"
                                        name="isSingleInstSelection"
                                    />
                                </FormGroup>
                            </Paper>
                        </Grid>
                        {competitionState.accessibility !== "invite-only" ?
                            <Grid item xs={12}>
                                <Typography variant="h6" sx={{mb: 3}}>
                                    g.) Email Blacklist
                                </Typography>
                                <EmailEntry
                                    emails={competitionState.blacklistedEmails}
                                    addEmails={handleAddBlacklistEmails}
                                    removeEmail={handleRemoveBlacklistEmail}
                                    disabled={action === "edit"}
                                />
                            </Grid> :
                            null
                        }
                    </Grid>
                </Grid>
                <Typography
                    variant="h5"
                    sx={{mb: 2}}
                >
                    4.)  Invitations
                </Typography>
                <Grid container spacing={2} sx={{mb: 12}}>
                    <Grid item xs={12}>
                        <EmailEntry
                            emails={competitionState.invitationEmails}
                            addEmails={handleAddInvitationEmails}
                            removeEmail={handleRemoveInvitationEmail}
                            disabled={action === "edit"}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{mb: 12}}>
                    <Grid item xs={12} sx={{textAlign: 'center'}}>
                        <LoadingButton
                            variant="contained"
                            component="label"
                            color="primary"
                            onClick={submit}
                            loading={loading}
                        >
                            Submit
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Container>
        )
    }
}
