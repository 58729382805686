import React, {useContext, useState} from "react";
import {Paper, IconButton, Divider, Typography, Container, Grid, Tooltip} from "@mui/material";
import {SxProps} from "@mui/material/styles";
import {useNavigate, useParams} from "react-router-dom";
import {useScriptDetail, useShareAccess} from "../../hooks";
import {ErrorComponent, LoadingSpinner, ShareAccessModal} from "../../components";
import {DataFormatting} from "../../util";
import EditIcon from '@mui/icons-material/Edit';
import {parseISO} from "date-fns";
import {ScriptStepCard} from "./ScriptStepCard";
import PeopleIcon from '@mui/icons-material/People';
import {toast} from "react-toastify";
import {UserContext} from "../../context";

export function AdminScriptDetail() {

    const {user} = useContext(UserContext);
    const navigate = useNavigate();
    const {scriptId} = useParams();
    const {scriptDetail, requestStatus} = useScriptDetail(scriptId);
    const {sharedUsers, getSharedUsers} = useShareAccess("SCRIPT", scriptId);
    const [shareAccessModalOpen, setShareAccessModalOpen] = useState<boolean>(false);

    const scriptLength = DataFormatting.secondsToTimeString(
        scriptDetail.scriptSteps.reduce((p, c) => {
            const parsed = parseInt(c.size);
            return (isNaN(parsed)) ? p : p + parsed;
        }, 0)) ;
    const metaStyle: SxProps = {py: 3, textAlign: "center", minHeight: 110};

    const handleOpenShareAccessModal = () => {
        getSharedUsers()
            .then(_ => {
                setShareAccessModalOpen(true);
            })
            .catch(e => {
                console.log(e);
                toast.error("There was a problem with this request");
            })
    }

    const handleCloseShareAccessModal = () => {
        setShareAccessModalOpen(false);
    }

    if (requestStatus === "loading") {
        return <LoadingSpinner />;
    } else if (requestStatus === "error") {
        return <ErrorComponent />;
    } else {

        const canSeeShareAccess = !!user && (user.userId === scriptDetail.createdBy);

        return (
            <Container sx={{mb: 10}}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h4">
                            Script Detail
                        </Typography>
                        <Divider sx={{my: 3}} />
                    </Grid>
                    <Grid container item spacing={2} xs={12} sx={{mb: 3}} alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <Typography variant="h6" sx={{mr: 2}}>
                                {`Script name:  ${scriptDetail.scriptName}`}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Tooltip title="See who has been shared this script.">
                                <IconButton
                                    onClick={handleOpenShareAccessModal}
                                    disabled={!canSeeShareAccess}
                                >
                                    <PeopleIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={2} alignItems="center" sx={{mb: 5}}>
                        <Grid item xs={12} sm={4}>
                            <Paper elevation={1} sx={metaStyle}>
                                <Typography variant="h6">
                                    {scriptDetail.scriptSteps.length}
                                </Typography>
                                <Typography variant="subtitle2">
                                    Prompts
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Paper elevation={1} sx={metaStyle}>
                                <Typography variant="h6">
                                    {scriptLength}
                                </Typography>
                                <Typography variant="subtitle2">
                                    Length
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Paper elevation={1} sx={metaStyle}>
                                <Typography variant="subtitle1">
                                    {parseISO(scriptDetail.createDate + "Z").toLocaleString()}
                                </Typography>
                                <Typography variant="subtitle2">
                                    Create Date
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Typography variant="h4">
                                    Prompts
                                </Typography>
                            </Grid>
                            <Grid item>
                                <IconButton
                                    onClick={() => {navigate(`/admin/scripts/edit/${scriptId}`); window.scrollTo(0, 0);}}
                                    disabled={!scriptDetail.canEdit}
                                >
                                    <EditIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Divider sx={{my: 3}} />
                    </Grid>
                    <Grid item xs={12}>
                        {scriptDetail.scriptSteps.map((c, i) =>
                            <ScriptStepCard key={i} scriptStep={c} />
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h4">
                            Usage
                        </Typography>
                        <Divider sx={{my: 3}} />
                    </Grid>
                    <Grid item xs={12}>
                        {scriptDetail.usage && scriptDetail.usage.length > 0 ? 
                            <Grid container spacing={3}>
                                {scriptDetail.usage.map((c, i) =>
                                    <Grid item xs={12} sm={6} md={4} key={i}>
                                        <Paper elevation={1} sx={{p: 3}}>
                                            <Typography variant="h6" noWrap sx={{mb: 3}}>
                                                {c.competitionName}
                                            </Typography>
                                            <Typography variant="subtitle1" sx={{mb: 1}}>
                                                {`Round #${c.roundNumber}`}
                                            </Typography>
                                        </Paper>
                                    </Grid>  
                                )}
                            </Grid> :
                            <Paper elevation={1} sx={{p: 3}}>
                                <Typography variant="subtitle1">
                                    This Script is not currently in use.
                                </Typography>
                            </Paper> 
                        }
                    </Grid>
                </Grid>
                <ShareAccessModal
                    open={shareAccessModalOpen}
                    sharedUsers={sharedUsers}
                    onClose={handleCloseShareAccessModal}
                />
            </Container>
        )
    }
}
