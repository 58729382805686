import React from "react";
import {Container, Grid, Typography} from "@mui/material";
import {useResults} from "./useResults";
import {ResultsCompetitionStatus} from "./ResultsCompetitionStatus";
import {ReviewsByPrompt} from "./ReviewsByPrompt";
import {ResultsRoundSelect} from "./ResultsRoundSelect";
import {PromptAggregateBarChart} from "./PromptAggregateBarChart";
import {AggregateRoundChart} from "./AggregateRoundChart";
import {useParams} from "react-router-dom";
import {LoadingSpinner, ErrorComponent} from "../../components";

interface ResultsProps {
    userId?: string;
}

export function Results({userId}: ResultsProps) {

    const {competitionId} = useParams();
    const {
        requestStatus,
        competition,
        selectedRoundIndex,
        handleRoundChange
    } = useResults(competitionId, userId);

    if (requestStatus === "loading") {
        return <LoadingSpinner />;
    } else if (requestStatus === "error") {
        return <ErrorComponent />;
    } else {

        const roundsAvailableForSelection = competition.userScoreData?.map(c => c.roundNumber) ?? [];

        return (
            <Container sx={{mb: 10}}>
                <Grid container>
                    <Grid item xs={12} sx={{my: 2}}>
                        <Typography variant="h4">
                            {`${competition.competitionMeta?.name || ""}`}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{mb: 4}}>
                        {/* UserStatistics, ParticipantStatus*/}
                        <ResultsCompetitionStatus
                            participantStatus={competition.participantStatus}
                            userStatistics={competition.userStatistics}
                        />
                    </Grid>
                    {/* Round Select, if possible */}
                    {roundsAvailableForSelection.length > 1 ?
                        <Grid item xs={12} sx={{mb: 4}}>
                            <ResultsRoundSelect
                                selectedRoundIndex={selectedRoundIndex}
                                roundsAvailableForSelection={roundsAvailableForSelection}
                                handleRoundChange={handleRoundChange}
                            />
                        </Grid> :
                        null
                    }
                    {/* Collapsable section */}
                    {competition.userScoreData ?
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <ReviewsByPrompt
                                userScoreData={competition.userScoreData[selectedRoundIndex]}
                            />
                        </Grid> :
                        null
                    }
                    {/* Chart */}
                    {competition.userScoreData ?
                        <Grid item xs={12} sm={12} md={12} lg={8}>
                            <PromptAggregateBarChart
                                userScoreData={competition.userScoreData[selectedRoundIndex]}
                            />
                        </Grid> :
                        null
                    }
                    {/* Aggregate Chart if more than one round complete */}
                    {competition.userScoreData && roundsAvailableForSelection.length > 1 ?
                        <Grid item xs={12}>
                            <AggregateRoundChart
                                userScoreData={competition.userScoreData}
                            />
                        </Grid> :
                        null
                    }
                </Grid>
            </Container>
        )
    }
}