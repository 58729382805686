import React from "react";
import {Grid, Typography} from "@mui/material";
import {RoundStatisticsProps} from "../../data-types";
import {parseISO} from "date-fns";
import {GridColDef, GridToolbar} from "@mui/x-data-grid";
import {StyledGrid} from "./StyledGrid";

export function RoundStatistics({roundStatus, roundStatistics}: RoundStatisticsProps) {

    const numberPrompts = roundStatus!.numberPrompts!;

    let meta: JSX.Element;
    if (roundStatus) {

        meta =
            <Grid container item xs={12} sx={{mb: 3}}>
                <Grid item xs={12} sx={{mb: 3}}>
                    <Typography variant="body1" sx={{fontWeight: "bold", pl: 2}}>
                        {`Round ${roundStatus.roundNumber}`}
                    </Typography>
                </Grid>
                <Grid item xs={0} sm={0} md={1} />
                <Grid item xs={12} sm={12} md={10}>
                    <Typography variant="body1">
                        {`Heat Size:  ${roundStatus.heatSize}`}
                    </Typography>
                    <Typography variant="body1" sx={{mb: 3}}>
                        {`Number of prompts:  ${numberPrompts}`}
                    </Typography>
                    <Typography variant="body1">
                        {`Response phase:  ${parseISO(roundStatus.responseStartDate + "Z").toDateString()} - ${parseISO(roundStatus.responseEndDate + "Z").toDateString()}`}
                    </Typography>
                    <Typography variant="body1">
                        {`Review phase:  ${parseISO(roundStatus.reviewStartDate + "Z").toDateString()} - ${parseISO(roundStatus.reviewEndDate + "Z").toDateString()}`}
                    </Typography>
                </Grid>
                <Grid item xs={0} sm={0} md={1} />
            </Grid>
    } else {
        meta = <Grid item xs={12} />;
    }

    let statistics: JSX.Element;
    if (roundStatistics) {
        const maxNumberPrompts = roundStatistics.userScores.reduce((p, c) => {
            return Math.max(p, c.promptScores.length);
        }, 0);
        const sumsAndCounts = Array.from({length: maxNumberPrompts}, _ => {return {sum: 0, count: 0}});

        const promptColDefs: GridColDef[] = Array(maxNumberPrompts).fill(0).map((_, i) => {
            return {
                field: `prompt${i + 1}`,
                headerName: `Prompt ${i + 1}`,
                flex: 1
            }
        });

        // first, map the user prompt scores to a flat object based on number of prompts
        // [{userId: 1, username: "cat", totalScore: 123.42, prompt1: 6.5, prompt2: 2.0, ...}, ...]
        const mappedRows = roundStatistics.userScores.map((c) => {
            const prompts = c.promptScores.reduce((p, cc, i) => {
               return {...p, [`prompt${i + 1}`]: cc.score.toFixed(2)}
            }, {});
            return {
                userId: c.userId,
                username: c.username,
                firstName: c.firstName,
                lastName: c.lastName,
                email: c.email,
                totalScore: Number.parseFloat(c.totalScore.toFixed(2)), ...prompts
            }
        })

        // compute averages of each prompt
        for (const user of roundStatistics.userScores) {
            user.promptScores.forEach((c, i) => {
                sumsAndCounts[i].sum += c.score;
                sumsAndCounts[i].count += 1;
            })
        }

        const promptAverages = sumsAndCounts.reduce((p, c, i) => {
            const av = (c.count === 0) ? 0 : c.sum / c.count;
            return {...p, [`prompt${i + 1}`]: Number.parseFloat((av).toFixed(2))};
        }, {});

        const _average = sumsAndCounts.reduce((p, c) => {
            return {sum: p.sum + c.sum, count: p.count + c.count}
        }, {sum: 0, count: 0});

        const totalAverage = (_average.count === 0) ? 0 : _average.sum / _average.count;

        const averagesRow = {
            userId: -1,
            username: "(Averages)",
            firstName: "(Averages)",
            lastName: "",
            email: "(Averages)",
            totalScore: Number.parseFloat(totalAverage.toFixed(2)),
            ...promptAverages
        };

        const mappedAttributeRows = roundStatistics.attributeScoreAverages.map((c) => {
            const prompts = c.promptScores.reduce((p, cc, i) => {
                return {...p, [`prompt${i + 1}`]: Number.parseFloat(cc.score.toFixed(2))}
            }, {});
            return {attributeId: c.attributeId, attributeName: c.attributeName, ...prompts};
        })

        const statisticsColDefs: GridColDef[] = [
            {field: "username", headerName: "Username", flex: 0.75},
            {field: "name", headerName: "Name", flex: 1, valueGetter: (params) => params.row.firstName + " " + params.row.lastName},
            {field: "email", headerName: "Email", flex: 1},
            ...promptColDefs,
            {field: "totalScore", headerName: "Total Score", flex: 1}
        ]

        statistics =
                <Grid item xs={12} sm={12} md={10}>
                    <Typography variant="body1" sx={{fontWeight: "bold", mt: 3}}>
                        Overall Scores
                    </Typography>
                    <StyledGrid
                        columns={statisticsColDefs}
                        slots={{toolbar: GridToolbar}}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                                printOptions: {
                                    hideToolbar: true,
                                    pageStyle: ".MuiDataGrid-main {color: #000;}"
                                }
                            }
                        }}
                        rows={[averagesRow, ...mappedRows]}
                        getRowId={row => row.userId}
                        initialState={{pagination: {paginationModel: {pageSize: 6}}}}
                        pageSizeOptions={[6, 12, 24]}
                        autoHeight={true}
                        getRowClassName={(params) => (params.row.userId === -1) ? "highlighted-row" : ""}
                        density="compact"
                        sx={{mt: 5, mb: 5, width: {xs: "100%", sm: "100%", md: "90%"}}}
                    />
                    <Typography variant="body1" sx={{fontWeight: "bold", mt: 3}}>
                        Attribute Summary
                    </Typography>
                    <StyledGrid
                        columns={[
                            {field: "attributeName", headerName: "Attribute", flex: 1},
                            ...promptColDefs
                        ]}
                        rows={mappedAttributeRows}
                        getRowId={row => row.attributeId}
                        initialState={{pagination: {paginationModel: {pageSize: 6}}}}
                        pageSizeOptions={[6, 12, 24]}
                        autoHeight={true}
                        density="compact"
                        sx={{mt: 5, mb: 5, width: {xs: "100%", sm: "100%", md: "80%"}}}

                    />
                </Grid>
    } else {
        statistics =
            <Grid item xs={12} sm={12} md={10} sx={{textAlign: "center", padding: 3, bgcolor: "#333333"}}>
                <Typography variant="h6">
                    No results are currently available.  Check back after the round is over.
                </Typography>
            </Grid>;
    }

    return (
        <Grid container item xs={12} sx={{mb: 3, mt: 3}}>
            {meta}
            <Grid container item xs={12}>
                <Grid item xs={0} sm={0} md={1} />
                    {statistics}
                <Grid item xs={0} sm={0} md={1} />
            </Grid>
        </Grid>
    )

}