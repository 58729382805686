import React, {useState} from "react";
import {Container, Grid, Avatar, Typography, Divider, Paper, Button} from "@mui/material";
import {VideoJsPlayerOptions} from "video.js";
import {VideoPlayer, LoadingSpinner, ErrorComponent} from "../../components";
import {useParams, Link} from "react-router-dom";
import {useAdminResponseDetail} from "../../hooks";
import LoopIcon from '@mui/icons-material/Loop';
import {ReviewCard} from "./ReviewCard";

export function AdminResponseDetail() {

    const {responseId} = useParams();
    const{
        requestStatus,
        responseDetail
    } = useAdminResponseDetail(responseId);
    
    const [isResponse, setIsResponse] = useState<boolean>(true);
    const [peerReviews, setPeerReviews] = useState<boolean>(true);
    const videoOptions: VideoJsPlayerOptions = {
        autoplay: false,
        controls: true,
        userActions: {hotkeys: true},
        sources: isResponse ? responseDetail.responseSources : responseDetail.promptSources,
        fill: true,
        html5: {
            nativeTextTracks: false
        }
    }

    if (requestStatus === "loading") {
        return <LoadingSpinner />;
    } else if (requestStatus === "error") {
        return <ErrorComponent />;
    } else {
        return (
            <Container sx={{mb: 10}}>
                <Grid container spacing={1} item xs={12} justifyContent="center" alignItems="center">
                    {/*  Avatar's response to prompt   */}
                    <Grid item xs={2} sm={1}>
                        <Avatar
                            src={responseDetail.responderAvatar ? `data:image/jpeg;base64,${responseDetail.responderAvatar}` : ""}
                            alt={`${responseDetail.responderUsername}'s Avatar`}
                        />
                    </Grid>
                    <Grid item xs={10} sm={11}>
                        <Typography variant="h4" display="inline" component={Link} to={`/admin/userDetail/${responseDetail.responderId}`} sx={{color: 'white'}}>
                            {`${responseDetail.responderUsername}`}
                        </Typography>
                        <Typography variant="h4" display="inline">
                            {`'s response to `}
                        </Typography>
                        <Typography variant="h4" display="inline" component={Link} to={`/admin/round/detail/${responseDetail.roundId}/scriptStep/detail/${responseDetail.scriptStepId}`} sx={{color: 'white'}}>
                            {`${responseDetail.promptVideoName}`}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider sx={{my: 3}} />
                    </Grid>
                </Grid>
                <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{mb: 5}}>
                    <Grid container spacing={2} item xs={12} sm={12} md={6}>
                        {/*  Score and attribute section  */}
                        <Grid item xs={12}>
                            <Paper elevation={1} sx={{p: 5, textAlign: "center"}}>
                                <Typography variant="h5">
                                    {responseDetail.averageRawScore ?
                                        `Average Score Received:  ${responseDetail.averageRawScore}` :
                                        `No scores have been given for this response.`
                                    }
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                Avg. Attribute Scores
                            </Typography>
                            <Divider sx={{my: 3}} />
                        </Grid>
                        {
                            responseDetail.averageAttributeScores ?
                                responseDetail.averageAttributeScores.map((c, i) =>
                                    <Grid key={i} item xs={12} sm={12} md={4}>
                                        <Paper elevation={1} sx={{p: 2, textAlign: "center"}}>
                                            <Typography variant="h6">
                                                {c.attributeScore.toFixed(2)}
                                            </Typography>
                                            <Typography variant="subtitle2">
                                                {c.attributeName}
                                            </Typography>
                                        </Paper>
                                    </Grid>
                                ) :
                                <Grid item xs={12}>
                                    <Paper elevation={1} sx={{p: 5, textAlign: "center"}}>
                                        <Typography variant="subtitle2">
                                            There are no attributes recorded for this response.
                                        </Typography>
                                    </Paper>
                                </Grid>
                        }
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} sx={{textAlign: "center"}}>
                        {/*  Video + toggle  */}
                        <VideoPlayer
                            options={videoOptions}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setIsResponse(prev => !prev)}
                            sx={{mt: 3}}
                            startIcon={<LoopIcon />}
                        >
                            {`Toggle to ${isResponse ? "Prompt" : "Response"}`}
                        </Button>
                    </Grid>
                    <Grid item xs={12} sx={{textAlign: "center"}}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setPeerReviews(prev => !prev)}
                            startIcon={<LoopIcon />}
                        >
                            {`Toggle to ${peerReviews ? "proxy reviews" : "peer reviews"}`}
                        </Button>
                    </Grid>
                </Grid>
                {
                    peerReviews ?
                        responseDetail.reviews?.map((c, i) =>
                            <ReviewCard review={c} responseId={responseId} key={i} />
                        ) :
                        responseDetail.proxyReviews?.map((c, i) =>
                            <ReviewCard review={c} responseId={responseId} key={i} />
                        )
                }
                <Grid container spacing={2}>
                    <Grid item xs={12} sx={{mt: 5, textAlign: "center"}}>
                        <Typography variant="subtitle2">
                            {`${peerReviews ? 
                                (responseDetail.reviews ? responseDetail.reviews.length : 0) : 
                                (responseDetail.proxyReviews ? responseDetail.proxyReviews.length : 0)} reviews`}
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        )
    }
}