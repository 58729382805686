import React, {useContext, useState, useEffect} from "react";
import {Paper, Button, Container, Divider, Grid, Typography, Tabs, Tab} from "@mui/material";
import {Link} from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import HelpIcon from '@mui/icons-material/Help';
import {formatError} from "../../util";
import {ScriptType} from "../../data-types";
import {useScripts, useScriptShare, useScriptDelete, useScriptCopy} from "../../hooks";
import {ShareScriptModal, ConfirmActionModal, CopyScriptModal} from "../../components";
import {CreatedScriptCard} from "./CreatedScriptCard";
import {SharedScriptCard} from "./SharedScriptCard";
import {PublicScriptCard} from "./PublicScriptCard";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {UserContext} from "../../context";

export function AdminScripts() {

    const {user} = useContext(UserContext);
    const navigate = useNavigate();
    const [scriptType, setScriptType] = useState<ScriptType>("created");
    const [selectedScriptId, setSelectedScriptId] = useState<number>(NaN);
    const [isSelectedScriptFinalized, setIsSelectedScriptFinalized] = useState<boolean>(false);
    const [shareModalOpen, setShareModalOpen] = useState<boolean>(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
    const [copyModalOpen, setCopyModalOpen] = useState<boolean>(false);
    const {
        toShareWith,
        canShare,
        shareVideos,
        handleChangeShare,
        submitShare,
        resetShare
    } = useScriptShare();
    const {submitDelete} = useScriptDelete();
    const {
        newScriptName,
        handleChangeCopy,
        submitCopy,
        setDefaultCopyName
    } = useScriptCopy();

    // services to get created (my), shared (with me) and public scripts
    const {
        scripts: createdScripts,
        requestStatus: createdRequestStatus
    } = useScripts("created");
    const {
        scripts: sharedScripts,
        requestStatus: sharedRequestStatus
    } = useScripts("shared");
    const {
        scripts: publicScripts,
        requestStatus: publicRequestStatus
    } = useScripts("public");


    const createdScriptCount = createdScripts.length;
    const sharedScriptCount = sharedScripts.length;
    const publicScriptCount = publicScripts.length;

    useEffect(() => {
        if (createdRequestStatus === "complete" && sharedRequestStatus === "complete" && publicRequestStatus === "complete") {
            if (createdScriptCount > 0) {
                setScriptType("created");
            } else if (sharedScriptCount > 0) {
                setScriptType("shared");
            } else if (publicScriptCount > 0) {
                setScriptType("public");
            } else {
                setScriptType("created");
            }
        }
    }, [createdRequestStatus, sharedRequestStatus, publicRequestStatus, createdScriptCount, sharedScriptCount, publicScriptCount]);

    // handler for the 3 item tab bar (My Scripts, Shared With Me, Public Templates
    // so we know what to render
    const handleTabChange = (_: React.SyntheticEvent, newValue: string) => {
        setScriptType(newValue as ScriptType);
    }

    // passed to each shareable script card so that it can set itself as selected
    // and open the share modal
    const handleShareClick = (id: number, isFinalized: boolean) => {
        setSelectedScriptId(id);
        setIsSelectedScriptFinalized(isFinalized);
        setShareModalOpen(true);
    }

    // same as handleShareClick but for delete
    const handleDeleteClick = (id: number) => {
        setSelectedScriptId(id);
        setDeleteModalOpen(true);
    }

    const handleCopyClick = (id: number, scriptName: string) => {
        setSelectedScriptId(id);
        let newDate = new Date();
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        setDefaultCopyName(`${scriptName} + (copy) + ${month}/${date}/${year}`);
        setCopyModalOpen(true);
    }

    const copyAction = () => {
        submitCopy(newScriptName, selectedScriptId)
            .then(_ => {
                toast.success("Script Copied Successfully.");
                setCopyModalOpen(false);
                navigate(0);
            })
            .catch(e => {
                console.log(e);
                toast.error(formatError(e));
            })
    }

    // this is what happens when user clicks 'Submit' in the Share modal
    const shareAction = () => {
        submitShare(toShareWith, selectedScriptId, canShare, shareVideos)
            .then(_ => {
                toast.success("Script Shared Successfully.");
                shareModalClose(false);
            })
            .catch(e => {
                console.log(e);
                toast.error(formatError(e));
            })
    }

    // wrapper around ModalProps.setOpen so we can nullify useCompetitionShare values
    const shareModalClose = (open: boolean) => {
        if (!open) {
            setShareModalOpen(open);
            resetShare();
        }
    }

    // same as shareAction but for delete
    const deleteAction = () => {
        submitDelete(selectedScriptId)
            .then(_ => {
                toast.success("Script Deleted Successfully.");
                setDeleteModalOpen(false);
                navigate(0);
            })
            .catch(e => {
                console.log(e);
                toast.error(formatError(e));
            })
    }

    const roleCanCreateCopyShare = !!user && (
        user.role === "admin" || user.role === "ps_admin"
    )

    return (
        <Container sx={{mb: 10}}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Typography variant="h4">
                        Admin Scripts
                    </Typography>
                </Grid>
                <Grid item xs={6} sx={{textAlign: "right"}}>
                    {roleCanCreateCopyShare ?
                        <Button
                            component={Link}
                            to="/admin/scripts/create"
                            variant="contained"
                            color="primary"
                            startIcon={<AddCircleIcon />}
                        >
                            Create
                        </Button> :
                        null
                    }
                </Grid>
                <Grid item xs={12} sx={{textAlign: "center"}}>
                    <Tabs
                        value={scriptType}
                        onChange={handleTabChange}
                        sx={{mb: 5}}
                        centered
                        textColor="inherit"
                    >
                        <Tab value="created" label={`My Scripts ${createdScripts.length > 0 ? `(${createdScripts.length})` : ""}`} disabled={!roleCanCreateCopyShare} />
                        <Tab value="shared" label={`Shared With Me ${sharedScripts.length > 0 ? `(${sharedScripts.length})` : ""}`} />
                        <Tab value="public" label={`Public Templates ${publicScripts.length > 0 ? `(${publicScripts.length})` : ""}`} />
                    </Tabs>
                </Grid>
                <Grid item xs={12}>
                    <Paper elevation={1} sx={{p: 4}}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs={12} sm={2} sx={{textAlign: "center"}}>
                                <HelpIcon />
                            </Grid>
                            <Grid item xs={12} sm={10}>
                                {scriptType === "created" ?
                                    <>
                                        <Typography variant="subtitle2" sx={{mb: 2}}>
                                            The following are scripts that you have created.
                                        </Typography>
                                        <Typography variant="subtitle2" sx={{mb: 2}}>
                                            Please note that once your script is used, either by you OR by somebody that you've shared it with, then the script will be set to "Closed for modification".  At this point, you may not rename the script or change any of its prompts.  You can still share it with others.  You can also manually close your script to modifications by editing it and selecting the appropriate option.
                                        </Typography>
                                        <Typography variant="subtitle2">
                                            When you delete a script, the videos used in the script will NOT be deleted.
                                        </Typography>
                                    </> : (scriptType === "shared") ?
                                        <>
                                            <Typography variant="subtitle2" sx={{mb: 2}}>
                                                The following are scripts that other admins have shared with you.
                                            </Typography>
                                            <Typography variant="subtitle2" sx={{mb: 2}}>
                                                When you use a script that has been shared with you, it closes it to modification from the creator.  The admin that shared this script for you assumes this risk.
                                            </Typography>
                                            <Typography variant="subtitle2">
                                                You can share this script with others if the original creator has permitted it.
                                            </Typography>
                                        </> :
                                        <>
                                            <Typography variant="subtitle2" sx={{mb: 2}}>
                                                The following are Public Templates that an admin has made available to all other admins.
                                            </Typography>
                                            <Typography variant="subtitle2" sx={{mb: 2}}>
                                                You can make your own copy of this script, but you can't share it because all admins already have access to it.
                                            </Typography>
                                        </>
                                }
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Divider sx={{my: 3}} />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                {scriptType === "created" ?
                    <>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">
                                {`${createdScriptCount} Scripts`}
                            </Typography>
                        </Grid>
                        {createdScripts.map((c, i) =>
                            <CreatedScriptCard
                                key={i}
                                script={c}
                                handleCopyClick={handleCopyClick}
                                handleShareClick={handleShareClick}
                                handleDeleteClick={handleDeleteClick}
                                roleCanCreateCopyShare={roleCanCreateCopyShare}
                            />
                        )}
                    </> :
                    null
                }
                {scriptType === "shared" ?
                    <>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">
                                {`${sharedScriptCount} Scripts`}
                            </Typography>
                        </Grid>
                        {sharedScripts.map((c, i) =>
                            <SharedScriptCard
                                key={i}
                                script={c}
                                handleCopyClick={handleCopyClick}
                                handleShareClick={handleShareClick}
                                roleCanCreateCopyShare={roleCanCreateCopyShare}
                            />
                        )}
                    </> :
                    null
                }
                {scriptType === "public" ?
                    <>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">
                                {`${publicScriptCount} Templates`}
                            </Typography>
                        </Grid>
                        {publicScripts.map((c, i) =>
                            <PublicScriptCard
                                key={i}
                                script={c}
                                handleCopyClick={handleCopyClick}
                                roleCanCreateCopyShare={roleCanCreateCopyShare}
                            />
                        )}
                    </> :
                    null
                }
            </Grid>
            <ShareScriptModal
                open={shareModalOpen}
                setOpen={shareModalClose}
                action={shareAction}
                value={toShareWith}
                canShare={canShare}
                shareVideos={shareVideos}
                handleChange={handleChangeShare}
                isFinalized={isSelectedScriptFinalized}
            />
            <CopyScriptModal
                open={copyModalOpen}
                setOpen={setCopyModalOpen}
                action={copyAction}
                value={newScriptName}
                handleChange={handleChangeCopy}
            />
            <ConfirmActionModal
                open={deleteModalOpen}
                setOpen={setDeleteModalOpen}
                action={deleteAction}
                confirmActionText="Delete Script"
            >
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h6" sx={{mb: 2}}>
                            Delete Script
                        </Typography>
                        <Typography variant="subtitle2">
                            Are you sure you want to delete this script?  This cannot be undone.
                        </Typography>
                    </Grid>
                </Grid>
            </ConfirmActionModal>
        </Container>
    )
}
