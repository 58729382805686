import React, {ChangeEvent, useState} from "react";
import {Container, Grid, MenuItem, TextField, Typography} from "@mui/material";
import {LoadingSpinner, ErrorComponent, NoDataCard} from "../../components";
import {useCompetitionDetail} from "../../hooks";
import {useParams} from "react-router-dom";
import {AdminProxyReviewResults} from "../admin-proxy-review-results";

export function AdminProxyRoundResults() {

    const {competitionId} = useParams();
    const {
        requestStatus,
        competitionDetail
    } = useCompetitionDetail(competitionId);
    const [selectedRound, setSelectedRound] = useState<number>(NaN);

    const handleRoundChange = (e: ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;
        setSelectedRound(parseInt(value));
    }

    if (requestStatus === "loading") {
        return <LoadingSpinner />;
    } else if (requestStatus === "error") {
        return <ErrorComponent />;
    } else {

        const roundsWithProxy: [number, number][] = competitionDetail!.roundMeta.filter(rm => rm.hasProxy).map(rm => [rm.roundId, rm.roundNumber]);

        return (
            <>
                <Container sx={{mb: 10}}>
                    {roundsWithProxy.length === 0 ?
                        <NoDataCard message="No proxy review data available." hideHomeButton={true} /> :
                        <Grid container alignItems="center" justifyContent="center" spacing={2}>
                            <Grid item>
                                <Typography variant="subtitle2">
                                    Select a Round
                                </Typography>
                            </Grid>
                            <Grid item>
                                <TextField
                                    select
                                    sx={{width: 250}}
                                    label="Round"
                                    name="round"
                                    id="round"
                                    onChange={handleRoundChange}
                                    value={selectedRound}
                                >
                                    {roundsWithProxy.map((c, i) =>
                                        <MenuItem key={i} value={c[0]}>
                                            {`Round ${c[1]}`}
                                        </MenuItem>
                                    )}
                                </TextField>
                            </Grid>
                        </Grid>
                    }
                </Container>
                {isNaN(selectedRound) ?
                    null :
                    <AdminProxyReviewResults round={isNaN(selectedRound) ? undefined : selectedRound.toString()} />
                }
            </>
        )
    }
}