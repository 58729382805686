import {useState, useCallback} from "react";
import {DataAccess} from "../../util";
import {SharedUser, SharedResourceType} from "../../data-types";

export const useShareAccess = (resourceType: SharedResourceType, resourceId?: string) => {

    const [sharedUsers, setSharedUsers] = useState<SharedUser[]>([]);

    const getSharedUsers = useCallback(async () => {
        if (resourceId) {
            const route = (resourceType === "EVENT") ?
                "/api/competition/" :
                resourceType === "VIDEO_PROMPT" ?
                    "/api/videoPrompt/" :
                    "/api/script/";
                const r = await DataAccess.get(route + resourceId + "/getAdmins.json");
                setSharedUsers(r.sharedUsers);
        }
    }, [resourceId, resourceType]);

    return {
        sharedUsers: sharedUsers,
        getSharedUsers: getSharedUsers
    }
}